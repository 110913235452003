import gipLogo from './gip-logo.svg';
import './App.css';
import { Box, Button, CssBaseline, Stack, ThemeProvider, Typography, createTheme } from '@mui/material';
import '@fontsource/poppins';

const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        sx={{ minHeight: '100vh' }}
      >
        <Box>
          <Box alt='Green innovation park logo' component={'img'} src={gipLogo} sx={{
            mt: 2,
            maxWidth: '98vw',
            width: '452px',
            height: '125px'
          }} >

          </Box>
        </Box>
        <Box sx={{ mt: 10 }}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography textAlign={'center'} sx={{
              color: '#000',
              fontFamily: 'Poppins',
              fontSize: '26px',
              fontWeight: 'bold',
              letterSpacing: '-0.26px',
              lineHeight: '100%',
              mb: 2
            }}>
              Välj din ort
            </Typography>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Button variant="contained" href="https://greeninnovationparkalnarp.spaces.nexudus.com/" sx={{
                background: '#1b8542',
                pl: 4,
                pr: 4,
                fontFamily: 'Poppins'
              }}>Alnarp</Button>

              <Button variant="contained" href="https://greeninnovationparkultuna.spaces.nexudus.com/" sx={{
                background: '#1b8542',
                pl: 4,
                pr: 4,
                fontFamily: 'Poppins'
              }}>Ultuna</Button>
            </Stack>
          </Stack>
        </Box>

        <Box
          component="footer"
          sx={{
            strokeWidth: '1px',
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            mt: 'auto',
            minWidth: '100vw'
          }}
        >
          <Typography textAlign={'center'} variant="body1" sx={{
            color: '#FFF',
            fontFamily: 'Poppins',
            fontSize: { xs: '20px', sm: '20px' },
            fontWeight: '500',
            backgroundColor: '#1b8542',
            clipPath: 'polygon(0 25%, 100% 0, 100% 100%, 0% 100%);',
            pt: 10,
            pb: 5
          }}>
            &#169; {new Date().getFullYear()} Green Innovation Park
          </Typography>
        </Box>
      </Stack>
    </ThemeProvider>
  );
}

export default App;
